/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "4661717",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "4661730",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "4661733",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "4661736",
  },
];
export default {
  NeoslavExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/neoslav/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема Неослав?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Возрождение этнических традиций и народного творчества через графическое пространство. Неослав или Неославянское возрождение — это обновление славянской стилистики и переосмысление русской культуры. Используется в айдентике городов, когда необходимо воссоздать культурный и исторический образ. Это графсистема подойдёт для тех, кто хочет научиться вычищать и собирать народные мотивы и славянские символы в векторной форме. Современные народные узоры, орнаменты и даже пиксельная графика. Всё это есть в графсистеме Неослав",
    },
  ],
  priceRange,
};
