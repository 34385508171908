// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import MasterClassContent from "components/pages/course-page/master-class-content";
// assets
import ogImage from "assets/images/seo/neoslav.jpg";
import ogImageVK from "assets/images/seo/vk/neoslav.jpg";
// data
import localData from "data/pages/neoslav";
import teamData from "data/team";
// hooks
import useNeoslavPortfolio from "hooks/portfolio/useNeoslavPortfolio";
import useNeoslavQuery from "hooks/graphql/useNeoslavQuery";
import useNeoslavOfferPreview from "hooks/useNeoslavOfferPreview";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

// 15.03.2024 Неослав скрыли
const NeoslavPage = () => {
  const prismicData = useNeoslavQuery();
  const portfolio = useNeoslavPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};

  const vadim = teamData.find(({ name }) => name === "Вадим Гранич");
  vadim.category = "Арт-директор";

  const sasha = teamData.find(({ name }) => name === "Саша Буяк");
  sasha.category = "Автор";

  const authors = [
    {
      authorInfo: sasha,
      authorDescription: (
        <>
          <p>Графический дизайн и исследование</p>
          <p>Запись и ведение видеолекций</p>
        </>
      ),
    },
    {
      authorInfo: vadim,
      authorDescription: (
        <>
          <p>Арт-директор при разработке графсистемы</p>
        </>
      ),
    },
  ];

  const offerImage = useNeoslavOfferPreview();

  return (
    <Layout logo="neoslav">
      <div className="section-top-block" />
      <SEO
        title="Графсистема Неослав"
        description={
          seoData?.seodescription?.text ||
          "Графическое переосмысление визуального кода славянских народов"
        }
        keywords={["неослав", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemNeoslav)}
        noIndex
      />
      <MasterClassContent
        disable
        offerWithPreview={offerImage}
        courseOffer={{ data }}
        courseExplanations={{ data: localData.NeoslavExplanations }}
        courseTitle={data.coursetitle?.text}
        coursePortfolio={{
          pinterestText: "Неослав в Энциклопедии\nГрафдизайна Школы",
          pinterestLink: "https://www.pinterest.ru/vadim_granich/neoslav/",
          portfolio,
          graphicSystemName: "Неослав",
        }}
        coursePriceRange={{
          formId: "ltForm4630442",
          priceRange: localData.priceRange,
          formPostUrl:
            "https://school.granich.design/pl/lite/block-public/process-html?id=1778424809",
        }}
        authors={authors}
        fixedPortfolioHeight
      />
    </Layout>
  );
};

export default NeoslavPage;
